import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React, { useState } from "react";
import Declaration1 from "./declaration1";
import Declaration2 from "./declaration2";
import Declaration3 from "./declaration3";
import Thankyou from "../ThankYou";
import BGLoading from "../BGLoading/BGLoading";
import LoginComponent from "../Login/Login";
import generatePDF from "react-to-pdf";
import { DatabaseService } from "../../Services/DatabaseService";
import OTPInput from "../OTPInput";

function TDSForm() {
  const [isTDSAmountCorrect, setIsTDSAmountCorrect] = useState(false);
  const [isAISTISCorrect, setIsAISTISCorrect] = useState();
  const [formError, setFormError] = useState(false);
  const [checked, setChecked] = useState(true);
  const [FinalSubmitted, setFinalSubmitted] = useState(false);
  const [FullScreenLoading, setFullScreenLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);
  const [UserAuthenticated, setUserAuthenticated] = useState(false);
  const [declarationCompleteMessage, setDeclarationCompleteMessage] = useState("");
  const [userData, setUserData] = useState({
    mobile: "",
    driver_id: "",
    fullname: "",
    pan: "",
    location: "Delhi",
    tdsAmount: 200,
    aisAmount: 0,
    tisAmount: 0,
  });
  const [additionalValues, setAdditionalValues] = useState({
    short_term_capital_on_sale: 0,
    long_term_capital_on_sale: 0,
    dividend_income: 0,
    saving_bank_interest: 0,
    interest_on_fixed_deposits: 0,
    other_income: 0,
    interest_income_from_ppf: 0,
    long_term_capital: 0,
    other_income_exempt: 0,
    receipts_from_blusmart: 0,
    incentives_from_blusmart: 0,
    rental_income: 0,
    capital_gain_on_sale_of_shares: 0,
    capital_gain_on_sale_of_immovable_property: 0,
    dividend_income_of_tax: 0,
    interest_on_fixed_deposits_tax: 0,
  });
  const [declarationLoading, setDeclarationLoading] = useState(false);
  const [openOTPDialog, setOpenOTPDialog] = useState(false);
  const [OTPVerifing, setOTPVerifing] = useState(false);
  const [OTPSending, setOTPSending] = useState(false);
  const [OTPFinal, setOTPFinal] = useState("");
  const [OTPToken, setOTPToken] = useState("");
  const [invalidOTPError, setInvalidOTPError] = useState("");
  
  const handleTokenCheck = async (token) => {
    setFullScreenLoading(true);
    let response = await DatabaseService("GET", "driver");
    console.log("response----------------->",response) 
    setUserData({
      id: response.userData._id,
      mobile: response.userData.phoneNumber,
      driver_id: response.userData.driverId,
      fullname: response.userData.name,
      pan: response.userData.panId,
      tdsAmount: response.userData.taxAmount,
      location: response?.userData?.location || "Delhi", 
      aisAmount: 0,
      tisAmount: 0,
    })
    setTimeout(() => {
      setFullScreenLoading(false);
      setUserAuthenticated(true);
    }, 2000);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleTDSAmount = async (value) => {
    if (value === 1) {
      setIsTDSAmountCorrect(true);
      setCurrentState(2);
    } else {
      setIsTDSAmountCorrect(false);
    }
  };

  const handleAISTIS = async (value) => {
    if (value === 1) {
      setCurrentState(3);
      setIsAISTISCorrect(true);
    } else {
      setIsAISTISCorrect(false);
    }
  };

  const handleExtraIncomeSubmit = async (e) => {
    e.preventDefault();
    setFormError(false);
    const blankKeys = await Object.entries(additionalValues)
      .map(([key, value]) => (value === 0 ? key : null))
      .filter((key) => key === null);
    if (blankKeys.length >= 1) {
      setFormError(true);
      return;
    }
    setCurrentState(3);
  };

  const options = {
    filename: "using-function.pdf",
    page: {
      margin: 20
    }
  };

  const getTargetElement = () => document.getElementById("mainSection");

  const finalSubmitHandle = async (status) => {
    setDeclarationLoading(true);
    let response = await DatabaseService("PUT", "/driver/"+userData.id, { status: status });
    console.log("response---------------->",response);
    if(response.code === 200){
      if(status === "1"){
        setDeclarationCompleteMessage("Thank You for the declaration. Declaration reference number is "+ response.result.referenceToken);
        setOpenOTPDialog(false);
        setFinalSubmitted(true);
      }
      if(status === "0"){
        setDeclarationCompleteMessage("Thank You for the declaration. Please contact to admin if you have any query.");
        setFinalSubmitted(true);
        window.location.href = "https://wa.me/919311294320?text=Hi%2C%20I%20do%20not%20agree%20with%20the%20total%20income%20received%20amount%20on%20the%20Declaration%20Form%0A%0A%E0%A4%A8%E0%A4%AE%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%87%2C%20%E0%A4%AE%E0%A5%88%E0%A4%82%20%E0%A4%98%E0%A5%8B%E0%A4%B7%E0%A4%A3%E0%A4%BE%20%E0%A4%AA%E0%A4%A4%E0%A5%8D%E0%A4%B0%20%E0%A4%AE%E0%A5%87%E0%A4%82%20%E0%A4%A6%E0%A4%B0%E0%A5%8D%E0%A4%B6%E0%A4%BE%E0%A4%88%20%E0%A4%97%E0%A4%88%20%E0%A4%95%E0%A5%81%E0%A4%B2%20%E0%A4%86%E0%A4%AF%20%E0%A4%B8%E0%A5%87%20%E0%A4%B8%E0%A4%B9%E0%A4%AE%E0%A4%A4%20%E0%A4%A8%E0%A4%B9%E0%A5%80%E0%A4%82%20%E0%A4%B9%E0%A5%82%E0%A4%81%E0%A5%A4";
      }
      setDeclarationLoading(false);
    }
    setDeclarationLoading(false);
    // setFinalSubmitted(true);
    // generatePDF(getTargetElement, options);
  };

  const handleAgreeClick = async() => {
      setOTPSending(true);
      setOpenOTPDialog(true);
      let OTPSentRes = await DatabaseService("POST", "otp", { "phone": "+91"+userData.mobile});
      if(OTPSentRes.code === 200){
        setOTPToken(OTPSentRes?.otpToken);
        let response = await DatabaseService("PUT", "/driver/"+userData.id, { status: "2" });
        setOTPSending(false);
      }
  }

  const handleOTPChange = async (e) => {
    setOTPFinal(e);
  }

  const OTPSubmit = async () => {
    setOTPVerifing(true);
    setInvalidOTPError("");
    if(OTPFinal.length !== 6){
      setInvalidOTPError("Invalid OTP.");
      setOTPVerifing(false);
      return
    }
    let otpBody = {
      otp: OTPFinal,
      token: OTPToken
    }
    let otpResponse = await DatabaseService("POST", "otp/verify-otp", otpBody);
    if(otpResponse.code === 200){
      finalSubmitHandle(1);
      setOTPVerifing(false);
    }else{
      setInvalidOTPError("Invalid OTP.");
      setOTPVerifing(false);
    }
  }

  return (
    <>
      <div className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
      {
        FullScreenLoading ? 
          <BGLoading FullScreenLoading={FullScreenLoading} />
        : 
          <>
            {
              UserAuthenticated ?
              <div className="relative bg-white px-6 pt-2 pb-2 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-7xl sm:rounded-lg sm:px-10">
                <div className="mx-auto max-w-7xl">
                  {FinalSubmitted ? (
                    <Thankyou message={declarationCompleteMessage} />
                  ) : (
                    <div className="divide-y divide-gray-300/50" id="mainSection">
                      <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
                        {currentState === 1 && userData.location === "Delhi" ? (
                          <Declaration1
                            userData={userData}
                            isTDSAmountCorrect={isTDSAmountCorrect}
                            handleTDSAmount={handleTDSAmount}
                          />
                        ) : null}
                        {currentState === 1 && userData.location != "Delhi" ? (
                          <Declaration2
                            userData={userData}
                            isTDSAmountCorrect={isTDSAmountCorrect}
                            handleTDSAmount={handleTDSAmount}
                          />
                        ) : null}
                        {currentState === 3 ? (
                          <Declaration3 checked={checked} handleChange={handleChange} />
                        ) : null}
                      </div>
                      {/* {checked ? ( */}
                        <div className="w-full flex gap-5 justify-center py-4">
                          <Button
                            disabled={declarationLoading}
                            variant="contained"
                            sx={{
                              bgcolor: "#4ade80",
                              color: "#FFF",
                              "&:hover":{
                                bgcolor: "#4ade80",
                              },
                              px: 4,
                            }}
                            onClick={() => {
                              finalSubmitHandle("1");
                              // handleAgreeClick();
                            }}
                          >
                          <strong>Agree/{userData.location === "Delhi" ?  'सहमत' : 'ಒಪ್ಪಿಗೆ'}</strong>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#FF0000",
                              color: "#FFF",
                              "&:hover":{
                                bgcolor: "#FF0000",
                              }
                            }}
                            onClick={() => {
                              finalSubmitHandle("0");
                            }}
                          >
                            <strong>Disagree - Raise a Query/{userData.location === "Delhi" ?  'असहमत - प्रश्न उठाएं' : 'ಅಸಹಮತಿ - ಪ್ರಶ್ನೆ ಎತ್ತಿ'} </strong>
                          </Button>
                        </div>
                      {/* ) : null} */}
                    </div>
                  )}
                  </div>
                  <Dialog
                    open={openOTPDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>{"Verify Mobile Number"}</DialogTitle>
                    {
                      OTPSending ? 
                      <>
                          <DialogContentText id="alert-dialog-slide-description" className="px-6 pb-6 mb-4">
                            <BGLoading FullScreenLoading={OTPSending} />
                            A message with a verification code is sending to +91{userData.mobile}. /
                            {
                              userData.location === "Delhi" ? 
                              `+91${userData.mobile} पर एक सत्यापन कोड वाला संदेश भेजा जा रहा है।` : 
                              `+91${userData.mobile} ಗೆ ಪರಿಶೀಲನೆ ಕೋಡ್‌ಯುಕ್ತ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ.`
                            }
                          </DialogContentText>
                      </> 
                      : 
                      <>
                        <DialogContent className="flex flex-col items-center gap-4">
                          <DialogContentText id="alert-dialog-slide-description">
                            A message with a verification code has been sent to +91{userData.mobile}. Enter the code /
                            {
                              userData.location === "Delhi" ? 
                              `आपके नंबर +91${userData.mobile} पर सत्यापन कोड वाला संदेश भेजा गया है। कृपया कोड दर्ज करें।` : 
                              `ನಿಮ್ಮ +91${userData.mobile} ಸಂಖ್ಯೆಗೆ ದೃಢೀಕರಣ ಕೋಡ್ ಹೊಂದಿರುವ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸಲಾಗಿದೆ. ದಯವಿಟ್ಟು ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ.`
                            }
                            
                          </DialogContentText>
                            <OTPInput handleOTPChange={handleOTPChange} otp={OTPFinal} />
                            {
                              invalidOTPError ? 
                                <p className="text-red-500 text-xs text-center w-full">
                                  {invalidOTPError}
                                </p>
                              : null
                            }
                        </DialogContent>
                        <DialogActions>
                          <Button disabled={OTPVerifing} onClick={()=>{OTPSubmit()}}>Verify OTP</Button>
                        </DialogActions>
                      </>
                    }
                  </Dialog>
                </div>
              : 
                <LoginComponent handleToken={handleTokenCheck} />
            }
          </>
      }
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
})

export default TDSForm;